/** @jsx jsx */
import { Box, Flex, Grid, jsx, Text } from 'theme-ui'
import { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { useCartContext } from '~/context/cart-context'
import { formatMoney } from '~/utils/format'
import { Button } from '~/components/button'
import urls from '~/common/urls'
import { Formik, Form } from 'formik'
import theme from '~/gatsby-plugin-theme-ui'
import { navigate } from 'gatsby-link'
import useCheckoutLink from '~/hooks/use-checkout-link'

type Props = {
  price: number | null
  onPriceChange: (newPrice: number) => void
}
export const ShippingRatesForm: FC<Props> = ({ onPriceChange }) => {
  const { checkout, updateShippingLine, fetchCheckout } = useCartContext()
  const checkoutUrl = useCheckoutLink()
  const [selectedShipping, setSelectedShipping] = useState('')

  useEffect(() => {
    const getCheckout = async () => {
      await fetchCheckout()
    }

    getCheckout()
  }, [])

  useEffect(() => {
    if (checkout) {
      if (checkout.shippingLine !== null) {
        setSelectedShipping(checkout.shippingLine.handle)
        onPriceChange(checkout.shippingLine.price.amount)
      } else {
        if (checkout.availableShippingRates) {
          setSelectedShipping(checkout.availableShippingRates.shippingRates[0])
          onPriceChange(checkout.availableShippingRates.shippingRates[0].price.amount)
        }
      }
    }
  }, [checkout])

  const handleChange = (e: ChangeEventHandler<HTMLInputElement>) => {
    const selectedValue = checkout?.availableShippingRates.shippingRates.find(
      x => x.handle === e.target.value
    )
    setSelectedShipping(selectedValue?.handle)
    onPriceChange(selectedValue?.price.amount)
  }

  const handleSubmit = async () => {
    if (selectedShipping !== '' && checkoutUrl) {
      await updateShippingLine(selectedShipping)
      await navigate(checkoutUrl)
    }
  }

  return (
    <Formik
      initialValues={{
        select: selectedShipping,
      }}
      onSubmit={handleSubmit}
    >
      <Form>
        {checkout &&
          checkout.availableShippingRates &&
          checkout.availableShippingRates.ready &&
          checkout.availableShippingRates.shippingRates.map(item => (
            <label
              key={item.handle}
              sx={{
                display: 'flex',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <Box
                as="input"
                type="checkbox"
                value={item.handle}
                checked={selectedShipping === item.handle}
                onChange={handleChange}
                sx={{
                  background: 'red',
                  cursor: 'pointer',
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                  ':checked + div > .checkBlock': {
                    display: 'block',
                  },
                }}
              />
              <Box
                sx={{
                  mt: '7px',
                  mr: theme.space.md,
                  width: '21px',
                  height: '21px',
                  border: `1px solid ${theme.colors.bg}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  className="checkBlock"
                  sx={{
                    width: '10px',
                    height: '10px',
                    background: 'black',
                    display: 'none',
                  }}
                />
              </Box>
              <Flex
                sx={{
                  flexDirection: ['column', null, 'row'],
                  mb: ['12px', null, '20px'],
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    mb: ['11px', 0],
                    flex: 1,
                  }}
                >
                  <Text
                    sx={{
                      fontSize: [2, null, 3],
                      lineHeight: ['28px', null, '34px'],
                      color: 'text',
                      mb: ['4px'],
                    }}
                  >
                    {item.title}
                  </Text>
                  <Text
                    sx={{
                      fontSize: [2],
                      lineHeight: ['22px'],
                      maxWidth: '580px',
                    }}
                  >
                    {item.handle}
                  </Text>
                </Box>
                <Text
                  sx={{
                    fontSize: [2, null, 3],
                    lineHeight: ['28px', null, '34px'],
                    color: 'text',
                  }}
                >
                  {formatMoney(item.price.amount, item.price.currencyCode)}
                </Text>
              </Flex>
            </label>
          ))}
        <Grid
          columns={[1, 2]}
          sx={{
            mt: [73],
          }}
        >
          <Box>
            <Button label="Return to checkout" as="link" to={urls.purchase.checkout} />
          </Box>
          <Box>
            <Button
              label="Continue to payment"
              sxProps={{
                width: '100%',
              }}
              type="submit"
            />
          </Box>
        </Grid>
      </Form>
    </Formik>
  )
}
