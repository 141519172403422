/** @jsx jsx */
import { Box, Flex, jsx, Text } from 'theme-ui'
import { FC, ReactNode } from 'react'

type Props = {
  lines: Array<{
    label: string
    value: string | ReactNode
  }>
}

const ShippingHeader: FC<Props> = ({ lines }) => {
  return (
    <Box
      sx={{
        mt: 66,
        mb: ['48px', null, '73px'],
        fontSize: [2],
        lineHeight: ['28px'],
        color: 'txt',
        '> div:not(:last-child)': {
          mb: ['20px'],
        },
      }}
    >
      {lines.map(line => (
        <Flex
          key={line.label}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Flex
            as={Box}
            sx={{
              flex: 1,
              flexDirection: ['column', null, 'row'],
            }}
          >
            <Text
              sx={{
                width: ['auto', null, '70px'],
                mr: [null, null, '30px'],
              }}
            >
              {line.label}
            </Text>
            <Text
              sx={{
                color: 'text',
              }}
            >
              {line.value}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Box>
  )
}

export default ShippingHeader
