/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import Shipping from '~/templates/shipping'

type Props = {}

const ShippingPage: FC<Props> = () => <Shipping />

export default ShippingPage
