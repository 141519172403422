/** @jsx jsx */
import { Box, Heading, jsx, Text } from 'theme-ui'
import { FC, useState } from 'react'
import CheckoutFlowLayout from '~/components/checkout-flow-layout'
import ShippingHeader from '~/components/shipping-header'
import CartListSimple from '~/components/cart-list-simple'
import Summary from '~/components/checkout-flow-layout/summary'
import { formatMoney } from '~/utils/format'
import { getDiscountsList } from '~/utils/checkout'
import { useCartContext } from '~/context/cart-context'
import { ShippingRatesForm } from '~/components/shipping-rates-form'
import { CartDiscountForm } from '~/containers/cart-discount-form'

type Props = {}

const Shipping: FC<Props> = () => {
  const { checkout } = useCartContext()
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null)

  const onPriceChange = (newPrice: number) => {
    setSelectedPrice(newPrice)
  }

  return (
    <CheckoutFlowLayout
      title="Shipping"
      main={
        <Box>
          {checkout?.shippingAddress && (
            <Box as="section">
              <ShippingHeader
                lines={[
                  {
                    label: 'Contact',
                    value: checkout?.email,
                  },
                  {
                    label: 'Ship to',
                    value: (
                      <Text
                        sx={{
                          color: 'text',
                          span: {
                            display: ['block', null, 'inline-flex'],
                            ':not(:last-child):after': {
                              content: "','",
                              display: ['none', null, 'inline-flex'],
                              mr: [null, null, '5px'],
                            },
                          },
                        }}
                      >
                        <span>{checkout?.shippingAddress.address1}</span>
                        <span>
                          {checkout?.shippingAddress.province} {checkout?.shippingAddress.zip}
                        </span>
                        <span>{checkout?.shippingAddress.country}</span>
                      </Text>
                    ),
                  },
                ]}
              />
              <Heading
                sx={{
                  mb: ['36px', null, '53px'],
                  fontSize: [6, 6, 6, 6],
                  lineHeight: ['38px'],
                }}
              >
                Shipping Method
              </Heading>
              <ShippingRatesForm onPriceChange={onPriceChange} price={selectedPrice} />
            </Box>
          )}
        </Box>
      }
      side={
        <Box>
          <Box
            sx={{
              mt: [37],
              borderBottom: t => `1px solid ${t.colors.border}`,
            }}
          >
            <CartListSimple />
          </Box>
          <CartDiscountForm />
          <Summary
            rows={[
              {
                label: 'Subtotal',
                value:
                  checkout && checkout.lineItemsSubtotalPrice
                    ? formatMoney(
                        checkout.lineItemsSubtotalPrice.amount,
                        checkout.lineItemsSubtotalPrice.currencyCode
                      )
                    : formatMoney(0),
              },
              {
                label: 'Shipping',
                value: selectedPrice ? formatMoney(selectedPrice) : '?',
              },
            ]}
            discountRows={checkout ? getDiscountsList(checkout) : []}
            total={
              checkout && checkout.totalPrice
                ? formatMoney(checkout.totalPrice.amount, checkout.totalPrice.currencyCode)
                : formatMoney(0)
            }
          />
        </Box>
      }
    />
  )
}

export default Shipping
